<template>
 <div>
  <svg height="183" viewBox="0 0 183 183" width="183" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
   <defs>
    <linearGradient gradientUnits="objectBoundingBox" id="linear-gradient" x1="0.807" x2="0.5" y2="1">
     <stop :stop-color="color1" offset="0"/>
     <stop :stop-color="color2" offset="1"/>
    </linearGradient>
   </defs>
   <g id="icon_SSP" transform="translate(-3328 -2062)">
    <g id="icon_calendar" transform="translate(2938 -707)">
     <g data-name="Group 421" id="Group_421" transform="translate(15 -4)">
      <rect :fill="background" data-name="Rectangle 194" height="183" id="Rectangle_194" transform="translate(375 2773)" width="183"/>
     </g>
    </g>
    <g data-name="MDI / account-question" id="MDI_account-question" transform="translate(3357.729 2091.728)">
     <g id="Boundary" opacity="0">
      <rect :fill="background" data-name="Boundary" height="123.544" id="Boundary-2" width="123.544"/>
      <path d="M1,1V122.544H122.544V1H1M0,0H123.544V123.544H0Z" data-name="Boundary - Outline" fill="rgba(0,0,0,0)" id="Boundary_-_Outline"/>
     </g>
     <path d="M62.772,24.591A20.591,20.591,0,1,1,42.181,4,20.591,20.591,0,0,1,62.772,24.591M83.363,76.067v10.3H1v-10.3C1,64.691,19.429,55.477,42.181,55.477s41.181,9.214,41.181,20.591M101.379,58.05v7.721H93.658V58.05h7.721m-10.3-25.738H83.363V29.738a15.443,15.443,0,1,1,30.886,0,15.053,15.053,0,0,1-6.64,12.406l-1.544.978a10.6,10.6,0,0,0-4.684,8.751V52.9H93.658v-1.03a18.177,18.177,0,0,1,8.185-15.186l1.493-.978a7.129,7.129,0,0,0,3.192-5.971,7.721,7.721,0,0,0-15.443,0Z" data-name="Path / account-question" fill="url(#linear-gradient)" id="Path_account-question" transform="translate(4.148 16.591)"/>
    </g>
   </g>
  </svg>
 </div>
</template>
<script>
export default {
  props: ["color1", "color2", "background"],
};
</script>